"use client";
import "react-phone-number-input/style.css";
import React from "react";
import ReactPhoneInput from "react-phone-number-input";
import "./style.css";
interface PhoneInputProps {
  value?: string;
  isInternational?: boolean;
  onChange: (value: string | undefined) => void;
}
const PhoneInput = ({ value, isInternational, onChange }: PhoneInputProps) => {
  return (
    <div
      className={`custom-phone-input ${isInternational ? "" : "is-us-only"}`}
    >
      <ReactPhoneInput
        placeholder="Phone Number"
        id="phoneNumber"
        defaultCountry="US"
        value={value}
        international={false}
        onChange={(v) => onChange(v)}
      />
    </div>
  );
};

export default React.memo(PhoneInput);
