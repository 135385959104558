"use client";
import React, { useEffect, useState } from "react";
import { Divider, Drawer, Modal, message, Grid } from "antd";
const { useBreakpoint } = Grid;
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { usePathname, useRouter } from "next/navigation";

import LookupPhoneNumber from "./LookupPhoneNumber";
import RescheduleMain from "./RescheduleMain";
import FirstClient from "./FirstClient";
import RescheduleList from "./RescheduleList";
import SetupPayment from "./SetupPayment";

import { EditionJson } from "@/lib/api";
import { useApp } from "@/lib/hooks/useAppointment";
import { extractErrorMessage } from "@/lib/helpers";
import {
  ICreateAppointmentArgs,
  IRescheduleAppointmentArgs,
} from "@/lib/api/types";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);

export const LOOKUP_PHONE_NUMBER = "lookup";
export const SETUP_PAYMENT = "setup-payment";
export const FIRST_TIME_CLIENT = "first-time-client";
export const RESCHEDULE_MAIN = "reschedule-main";
export const RESCHEDULE_LIST = "reschedule-list";

export type IPathState =
  | typeof LOOKUP_PHONE_NUMBER
  | typeof FIRST_TIME_CLIENT
  | typeof SETUP_PAYMENT
  | typeof RESCHEDULE_MAIN
  | typeof RESCHEDULE_LIST;

interface AppointmentArgs {
  paymentMethodId?: string;
  errorCallback: () => void;
}

export interface INewAppointmentArgs extends AppointmentArgs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  code?: string;
}

export interface IRescheduleArgs extends AppointmentArgs {
  previousAppointmentId: string;
}

export type IAppointmentRouteState = {
  upcomingAppointments: any[];
  phoneNumber: string;
  client: {
    firstName: string;
    lastName: string;
  };
  paymentMethodId?: string;
};

const initialRoute = {
  path: LOOKUP_PHONE_NUMBER as IPathState,
  state: {
    upcomingAppointments: [],
    phoneNumber: "",
    client: {
      firstName: "Unknown",
      lastName: "Unknown",
      isBlocked: false,
    },
  },
};

const NewAppointmentModal = () => {
  const screens = useBreakpoint();
  const pathname = usePathname();
  const router = useRouter();
  const { isAppointmentModalActive, appointmentForm, closeAppointmentForm } =
    useApp();
  const [route, setRoute] = useState<{
    path: IPathState;
    state: IAppointmentRouteState;
  }>(initialRoute);

  const [messageApi, contextHolder] = message.useMessage();
  const onRouteChange = (path: IPathState, state: any) => {
    setRoute((prevRoute) => ({
      path,
      state: { ...prevRoute.state, ...state },
    }));
  };

  const handleAppointment = async <
    TArgs extends ICreateAppointmentArgs | IRescheduleAppointmentArgs
  >(
    action: (args: TArgs) => Promise<{ appointmentId: string }>,
    args: AppointmentArgs,
    additionalFields: any
  ) => {
    if (!appointmentForm) return;
    try {
      const { userId, scheduleId, time, date, services, requestId, onAfter } =
        appointmentForm;

      const res = await action({
        userId,
        scheduleId,
        time,
        date,
        services: services.map((item) => ({
          _id: item._id,
          category: item.category,
          ...(item.option && { optionId: item.option.id }),
        })),
        paymentMethodId: args.paymentMethodId,
        ...(requestId && { requestId }),
        ...additionalFields,
      } as TArgs);

      if (onAfter) {
        onAfter(res.appointmentId);
        closeAppointmentForm();
        setRoute(initialRoute);
      } else {
        router.push(`/appointment/review/${res.appointmentId}`); // Wait for the routing to complete
      }
    } catch (e: any) {
      handleMessage("error", extractErrorMessage(e));
      args.errorCallback();
    }
  };

  const onCreate = async (args: INewAppointmentArgs) => {
    await handleAppointment<ICreateAppointmentArgs>(
      EditionJson.createAppointment,
      args,
      {
        firstName: args.firstName,
        lastName: args.lastName,
        code: args.code,
        phoneNumber: args.phoneNumber,
      }
    );
  };

  const onReschedule = async (args: IRescheduleArgs) => {
    await handleAppointment<IRescheduleAppointmentArgs>(
      EditionJson.rescheduleAppointment,
      args,
      {
        rescheduleAppointmentId: args.previousAppointmentId,
      }
    );
  };

  const handleMessage = (
    type: "success" | "info" | "error",
    content: string
  ) => {
    messageApi.open({
      type,
      content,
    });
  };

  const componentMap: Record<IPathState, React.FC<any>> = {
    [LOOKUP_PHONE_NUMBER]: LookupPhoneNumber,
    [SETUP_PAYMENT]: SetupPayment,
    [FIRST_TIME_CLIENT]: FirstClient,
    [RESCHEDULE_MAIN]: RescheduleMain,
    [RESCHEDULE_LIST]: RescheduleList,
  };

  useEffect(() => {
    if (!isAppointmentModalActive) {
      setRoute(initialRoute);
    }
  }, [isAppointmentModalActive]);

  useEffect(() => {
    if (pathname.includes("/appointment/review")) {
      closeAppointmentForm();
      setRoute(initialRoute);
    }
  }, [pathname]);

  const CurrentComponent = componentMap[route?.path];
  return (
    <>
      {contextHolder}
      {screens.md ? (
        <Modal
          title="Appointment"
          open={isAppointmentModalActive}
          maskClosable={false}
          onCancel={closeAppointmentForm}
          footer={null}
          style={{ top: 20 }}
          destroyOnClose
        >
          <Divider dashed />
          <Elements
            stripe={stripePromise}
            options={{
              appearance: { theme: "stripe" },
              mode: "setup",
              currency: "usd",
              paymentMethodTypes: ["card"],
            }}
          >
            <CurrentComponent
              appointment={appointmentForm}
              routeState={route.state}
              onCreate={onCreate}
              onReschedule={onReschedule}
              onRouteChange={onRouteChange}
            />
          </Elements>
        </Modal>
      ) : (
        <Drawer
          title="Appointment"
          styles={{
            wrapper: {
              height: "100%",
            },
            mask: {
              background: "transparent",
            },
          }}
          autoFocus={false}
          placement="bottom"
          destroyOnClose
          open={isAppointmentModalActive}
          onClose={closeAppointmentForm}
        >
          <Elements
            stripe={stripePromise}
            options={{
              appearance: { theme: "stripe" },
              mode: "setup",
              currency: "usd",
              paymentMethodTypes: ["card"],
            }}
          >
            <CurrentComponent
              appointment={appointmentForm}
              routeState={route.state}
              onCreate={onCreate}
              onReschedule={onReschedule}
              onRouteChange={onRouteChange}
            />
          </Elements>
        </Drawer>
      )}
    </>
  );
};

export default NewAppointmentModal;
