export const appRoutes = [
  {
    label: "Appointment",
    path: "/appointment",
  },
  {
    label: "Stylists",
    path: "/stylists",
  },
  {
    label: "Services",
    path: "/services",
  },
  {
    label: "Photos",
    path: "/photos",
  },
  {
    label: "Shop",
    path: "/shop",
  },
  {
    label: "Blog",
    path: "/blog",
  },
  {
    label: "Contact",
    path: "/contact",
    isMobileOnly: true,
  },
  {
    label: "We are hiring",
    path: "/careers",
    isMobileOnly: true,
  },
];
