import React from "react";
import { Button, Divider, Space, Spin, Typography } from "antd";
import {
  IPathState,
  IRescheduleArgs,
  IAppointmentRouteState,
  RESCHEDULE_MAIN,
} from ".";
import { LeftOutlined } from "@ant-design/icons";
import { useApp } from "@/lib/hooks/useAppointment";
import { formatDatetime } from "@/lib/helpers";

interface IProps {
  routeState: IAppointmentRouteState;
  onReschedule: (args: IRescheduleArgs) => void;
  onRouteChange: (path: IPathState, state: any) => void;
}
const RescheduleList = ({
  routeState,
  onReschedule,
  onRouteChange,
}: IProps) => {
  const { appointmentForm } = useApp();
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = React.useState<any>();

  // Get from router state
  const upcomingAppointments = routeState.upcomingAppointments
    ? routeState.upcomingAppointments
    : [];

  const handleReschedule = () => {
    if (appointmentToCancel) {
      setSubmitting(true);
      onReschedule({
        previousAppointmentId: appointmentToCancel._id,
        paymentMethodId: routeState.paymentMethodId,
        errorCallback: () => {
          setSubmitting(false);
        },
      });
    }
  };

  const selectAppointmentToCancel = (appointment: any) =>
    setAppointmentToCancel(appointment);

  if (isSubmitting) {
    return (
      <div className="has-text-centered">
        <Spin />
        <br />
        <br />
        Your appointment rescheduling request is being processed. Please hold on
        a moment...
      </div>
    );
  }
  return (
    <>
      {appointmentToCancel ? (
        <RescheduleReview
          isSubmitting={isSubmitting}
          from={formatDatetime(
            appointmentToCancel.date,
            appointmentToCancel.time
          )}
          to={
            appointmentForm
              ? formatDatetime(appointmentForm.date, appointmentForm.time)
              : ""
          }
          onClick={handleReschedule}
        />
      ) : (
        <UpcomingList
          onBack={() => onRouteChange(RESCHEDULE_MAIN, routeState)}
          upcomingAppointments={upcomingAppointments}
          onClick={selectAppointmentToCancel}
        />
      )}
    </>
  );
};

const RescheduleReview = ({
  from,
  to,
  onClick,
  isSubmitting,
}: {
  from: string;
  to: string;
  onClick: () => void;
  isSubmitting: boolean;
}) => {
  return (
    <>
      <Typography.Paragraph>
        We&#39;re updating your appointment date from <strong>{from}</strong> to{" "}
        <strong>{to}</strong>
      </Typography.Paragraph>
      <Space>
        <Button loading={isSubmitting} onClick={onClick} type="primary">
          Confirm
        </Button>
      </Space>
    </>
  );
};

const UpcomingList = ({
  upcomingAppointments,
  onClick,
  onBack,
}: {
  upcomingAppointments: any[];
  onClick: (appointment: any) => void;
  onBack: () => void;
}) => {
  return (
    <>
      <Typography.Paragraph>
        Please click on the original appointment that you would like to
        reschedule.
      </Typography.Paragraph>

      <Space direction="vertical" style={{ width: "100%" }}>
        {upcomingAppointments.map((appointment: any) => (
          <Button
            key={appointment._id}
            block
            onClick={() => onClick(appointment)}
          >
            {formatDatetime(appointment.date, appointment.time)}
          </Button>
        ))}
      </Space>

      <Divider dashed />

      <Button onClick={onBack} icon={<LeftOutlined />}>
        Back
      </Button>
    </>
  );
};

export default RescheduleList;
