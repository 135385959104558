"use client";
import React, { useState } from "react";
import { Drawer, Menu, Layout, Grid, Avatar, Button } from "antd";
import Link from "next/link";
import Image from "next/image";
import { COMB_LOGO_SRC, LOGO_SRC } from "@/consts";
import { appRoutes } from "@/consts/routes";
import { useRouter, usePathname } from "next/navigation";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const MainHeader = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isSidebarActive, setSidebarActive] = useState(false);

  const onLinkClick = async (v: any) => {
    await router.push(v.key);
    setSidebarActive(false);
  };

  // Highlight active path by matching the start of the pathname
  const getActiveKey = () => {
    const matchedRoute = appRoutes.find((route) =>
      pathname.startsWith(route.path)
    );
    return matchedRoute?.path || "/";
  };

  const activeKey = getActiveKey();

  const routes = appRoutes.map((link) => ({
    key: link.path,
    label: link.label,
    isMobileOnly: link.isMobileOnly,
  }));

  const screens = useBreakpoint();
  const isMobile = !screens.md;

  return (
    <Layout>
      <Header
        style={{
          width: "100%",
          position: "fixed",
          zIndex: 100,
        }}
      >
        <div
          className="container"
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link
            href="/"
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              padding: "0 14px",
            }}
          >
            <Image
              width={160}
              height={40}
              alt="Eddie's Edition"
              src={LOGO_SRC}
            />
          </Link>
          {!isMobile && (
            <Menu
              selectedKeys={[activeKey]} // Highlight the active menu item
              theme="light"
              mode="horizontal"
              onClick={onLinkClick}
              items={routes.filter((r) => !r.isMobileOnly)}
              style={{
                marginLeft: "auto", // Push the menu to the right
                borderBottom: "none",
                boxShadow: "none",
              }}
            />
          )}

          {isMobile && (
            <Button
              style={{ width: "52px", height: "64px", borderRadius: 0 }}
              onClick={() => setSidebarActive(!isSidebarActive)}
              aria-label={"header mobile menu control"}
              color="default"
              variant="text"
              icon={<MenuOutlined />}
            />
          )}
        </div>
      </Header>

      <aside>
        <Drawer
          key="sidebar"
          width={250}
          placement="left"
          closeIcon={null}
          open={isSidebarActive}
          onClose={() => setSidebarActive(false)}
          zIndex={100}
          styles={{ body: { padding: 0, zIndex: 3 } }}
        >
          <div
            style={{
              height: "64px",
              padding: "0px 18px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Avatar alt="Eddie's Edition comb" src={COMB_LOGO_SRC} />
          </div>

          <Menu
            selectedKeys={[activeKey]} // Highlight the active menu item
            theme="light"
            onClick={onLinkClick}
            mode="inline"
            items={routes}
            style={{
              flex: 1,
              justifyContent: "flex-end", // Align menu items to the right inside the drawer
            }}
          />
        </Drawer>
      </aside>
    </Layout>
  );
};

export default MainHeader;
