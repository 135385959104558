import { Button } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";

interface ITroubleShootingForCodeProps {
  onClose: () => void;
}
const TroubleShootingForCode = ({ onClose }: ITroubleShootingForCodeProps) => {
  return (
    <>
      <Paragraph>
        If you&#39;re not receiving verification codes from us on your provided
        phone number, it&#39;s possible you may have opted out from our
        services. Please text &#39;START&#39; to 626-888-8052 and attempt the
        verification process again.
      </Paragraph>
      <Button onClick={onClose}>Go back</Button>
    </>
  );
};

export default TroubleShootingForCode;
