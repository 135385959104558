import React from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Button, Divider, Flex, Form, Typography, message } from "antd";
import { EditionJson } from "@/lib/api";

import AppointmentModalReview from "./components/AppointmentReview";

import {
  FIRST_TIME_CLIENT,
  INewAppointmentArgs,
  IPathState,
  RESCHEDULE_MAIN,
  SETUP_PAYMENT,
} from "./index";
import { AppointmentHistoryData } from "@/lib/api/types";
import { AppointmentForm } from "@/lib/hooks/useAppointment";
import PhoneInput from "../PhoneInput";
import { extractErrorMessage } from "@/lib/helpers";

interface IProps {
  appointment: AppointmentForm;
  onCreate: (args: INewAppointmentArgs) => void;
  onRouteChange: (path: IPathState, state: any) => void;
}
const LookupPhoneNumber = ({
  appointment,
  onRouteChange,
  onCreate,
}: IProps) => {
  const isCreditCardRequired = appointment
    ? appointment.services.some((service) => service.hasPayAdvance)
    : false;

  const [isInternational, setInternational] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const handleSubmit = async () => {
    if (!phoneNumber) return;

    if (!isValidPhoneNumber(phoneNumber)) {
      messageApi.error("Please enter a valid phone number");
      return;
    }

    setSubmitting(true);

    try {
      const res = await EditionJson.getAppointmentHistory(
        phoneNumber,
        appointment.userId as string
      );
      handleRoutingBasedOnResponse(res);
    } catch (e) {
      messageApi.error(extractErrorMessage(e));
      setSubmitting(false);
    }
  };

  const handleRoutingBasedOnResponse = async (
    phoneNumberHistory: AppointmentHistoryData
  ) => {
    if (!phoneNumber) return;
    const { upcomingAppointments, client, isNewClient } = phoneNumberHistory;

    if (isNewClient) {
      onRouteChange(FIRST_TIME_CLIENT, {
        phoneNumber,
        isCreditCardRequired,
      });
      return;
    }

    if ((client && client.blocked) || isCreditCardRequired) {
      onRouteChange(SETUP_PAYMENT, {
        phoneNumber,
        upcomingAppointments,
        client,
      });
      return;
    }

    if (phoneNumberHistory.upcomingAppointments.length > 0) {
      onRouteChange(RESCHEDULE_MAIN, {
        phoneNumber,
        upcomingAppointments,
        client,
      });
      return;
    }

    onCreate({
      firstName: client ? client.firstName : "Unknown",
      lastName: client ? client.lastName : "Unknown",
      phoneNumber,
      errorCallback: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <>
      {contextHolder}
      <AppointmentModalReview appointment={appointment} />
      <Form layout="vertical">
        <Form.Item
          label={<Typography.Text strong>Phone Number:</Typography.Text>}
          name="phoneNumber"
        >
          <PhoneInput
            isInternational={isInternational}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </Form.Item>
      </Form>
      <Divider dashed />
      <Flex justify="space-between" align="center">
        <Button
          onClick={handleSubmit}
          disabled={!phoneNumber || isSubmitting}
          loading={isSubmitting}
          type="primary"
        >
          Submit
        </Button>
        <Typography.Link
          onClick={() => setInternational(true)}
          type="secondary"
        >
          <small>International Number?</small>
        </Typography.Link>
      </Flex>
    </>
  );
};

export default LookupPhoneNumber;
