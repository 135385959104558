import React, { useState } from "react";
import { EditionJson } from "@/lib/api";
import TroubleShootingForCode from "./components/TroubleShootingForCode";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Space,
  Typography,
  message,
} from "antd";
import { INewAppointmentArgs } from ".";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { extractErrorMessage } from "@/lib/helpers";
import Text from "antd/lib/typography/Text";

interface IProps {
  routeState: any;
  onCreate: (args: INewAppointmentArgs) => void;
}
const FirstClient = ({ routeState, onCreate }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [messageApi, contextHolder] = message.useMessage();

  const { phoneNumber, isCreditCardRequired } = routeState;
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [code, setCode] = useState<string>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSending, setSending] = useState(false);
  const [showInstructure, setShowInstructure] = React.useState(false);
  // const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if (phoneNumber && firstName && lastName && code) {
      setSubmitting(true);

      const isVerified = await EditionJson.verifyVerificationCode(
        phoneNumber,
        code
      );

      if (isVerified) {
        if (stripe && elements && isCreditCardRequired) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements,
          });
          if (error) {
            messageApi.error(
              error.message ? error.message : "An error occurred"
            );
            setSubmitting(false);
            return;
          } else {
            try {
              await EditionJson.createPaymentMethod({
                firstName,
                lastName,
                phoneNumber,
                paymentId: paymentMethod.id,
              });
            } catch (error: any) {
              error();
              messageApi.error(error.message);
              setSubmitting(false);
              return;
            }
          }

          onCreate({
            firstName,
            lastName,
            phoneNumber,
            code,
            paymentMethodId: paymentMethod.id,
            errorCallback: () => {
              setSubmitting(false);
            },
          });
        } else {
          onCreate({
            firstName,
            lastName,
            phoneNumber,
            code,
            errorCallback: () => {
              setSubmitting(false);
            },
          });
        }
      } else {
        messageApi.error("Invalid Verification Code");
        setSubmitting(false);
      }
    }
  };

  const onSendVerificationCode = async () => {
    setSending(true);
    try {
      await EditionJson.sendVerificationCode(phoneNumber);
      messageApi.success("Sent");
    } catch (err: any) {
      messageApi.error(extractErrorMessage(err));
    }
    setSending(false);
  };

  if (showInstructure) {
    return <TroubleShootingForCode onClose={() => setShowInstructure(false)} />;
  }
  return (
    <>
      {contextHolder}
      <Typography.Paragraph>
        We&#39;ve sent a verification code to your mobile number. Please fill
        out the form with the code to continue.
      </Typography.Paragraph>

      <Space direction="vertical" style={{ width: "100%" }}>
        <Form layout="vertical">
          <Form.Item label={<Text strong>First Name</Text>} htmlFor="firstName">
            <Input
              id="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label={<Text strong>Last Name</Text>} htmlFor="lastName">
            <Input
              id="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={<Text strong>Verification Code</Text>}
            htmlFor="code"
          >
            <Input
              id="code"
              placeholder="Code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </Form.Item>
        </Form>

        {isCreditCardRequired && (
          <>
            <div>
              <div className="stripe-card-element-container">
                <CardElement />
              </div>
            </div>
            <div>
              <Typography.Text>
                <small>
                  By providing your card information, you authorize Eddie&#39;s
                  Edition to charge your card for future payments in accordance
                  with their terms. Please note that our cancellation policy
                  dictates a charge of the full service amount for no-shows or
                  cancellations made within 3 hours of the appointment.
                </small>
              </Typography.Text>
            </div>
          </>
        )}

        <div className="has-text-right">
          <Typography.Link
            type="secondary"
            onClick={() => setShowInstructure(true)}
          >
            <small>Having trouble receiving the code?</small>
          </Typography.Link>
        </div>
      </Space>
      <Divider dashed />
      <Flex justify="space-between" align="center">
        <Button
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting || !firstName || !lastName || !code}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          type="link"
          disabled={isSending || isSubmitting}
          onClick={onSendVerificationCode}
        >
          Re-send
        </Button>
      </Flex>
    </>
  );
};

export default FirstClient;
