"use client";
import React, { createContext, useContext, useState, useMemo } from "react";
import { Service, ServiceOption } from "../api/types";

// Define AppointmentForm and AppState
export interface AppointmentForm {
  date: string;
  time: string;
  stylistName: string;
  locationName?: string;
  userId: string;
  scheduleId: string;
  services: (Service & { option?: ServiceOption })[];
  requestId?: string;
  onAfter?: (appointmentId: string) => void;
}

export interface AppState {
  isAppointmentModalActive: boolean;
  appointmentForm?: AppointmentForm;
  date: Date;
}

interface IAppContext {
  appointmentForm?: AppointmentForm;
  isAppointmentModalActive: boolean;
  date: Date;
  openAppointmentForm: (form: AppointmentForm) => void;
  closeAppointmentForm: () => void;
  setDate: (date: Date) => void;
}

// Initial state
const initialState: AppState = {
  isAppointmentModalActive: false,
  appointmentForm: undefined,
  date: new Date(),
};

// Create context
export const AppContext = createContext<IAppContext>({
  appointmentForm: undefined,
  isAppointmentModalActive: false,
  date: new Date(),
  openAppointmentForm: () => {},
  closeAppointmentForm: () => {},
  setDate: () => {},
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useState<AppState>(initialState);

  // Functions for updating state
  const openAppointmentForm = (form: AppointmentForm) => {
    setState((prev) => ({
      ...prev,
      isAppointmentModalActive: true,
      appointmentForm: form,
    }));
  };

  const closeAppointmentForm = () => {
    setState((prev) => ({
      ...prev,
      isAppointmentModalActive: false,
      appointmentForm: undefined,
    }));
  };

  const setDate = (date: Date) => {
    setState((prev) => ({ ...prev, date }));
  };

  // ✅ Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      appointmentForm: state.appointmentForm,
      isAppointmentModalActive: state.isAppointmentModalActive,
      date: state.date,
      openAppointmentForm,
      closeAppointmentForm,
      setDate,
    }),
    [state.appointmentForm, state.isAppointmentModalActive, state.date] // Only re-create when needed
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

// Custom hook to use the context
export const useApp = () => useContext(AppContext);
